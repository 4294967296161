import Hero_2_img from "./hero-2.webp";
import CTA_bg_img from "./CTA-bg.webp";
import Features_3_img from "./feature3.webp";
import Features_2_1_img from "./feature2-1.webp";
import Features_2_2_img from "./feature2-2.webp";
import Features_2_3_img from "./feature2-3.webp";
import Features_2_4_img from "./feature2-4.webp";
import Logo from "./logo.png";
import CTA_img from "./CTA.webp";
import Logo_cyde from "./ico2.png";
import Error_img from "./error.webp";

export {
  Hero_2_img,
  Features_3_img,
  Features_2_1_img,
  Features_2_2_img,
  Features_2_3_img,
  Features_2_4_img,
  Logo,
  CTA_img,
  CTA_bg_img,
  Logo_cyde,
  Error_img,
};
